import React, {useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/navbar';
import Footer from './components/footer';
import Mainhome from './components/mainHome';
import Selectpage from './components/pages/selectPage';
import Page1 from './components/pages/page1';
import Page2 from './components/pages/page2';
import Page3 from './components/pages/page3';
import Page4 from './components/pages/page4';
import Slot from './components/slot';
import PrivacyPolicy from './components/privacyPolicy';
import Refund from './components/refund';
import TermAndCondition from './components/termAndCondition';
import SuccessPage from './components/successPayment';
import FailPage from './components/failPayment';
import SuccessStory from './components/successStory';
import SuccessStoryList from './components/successStoryList';

function App() {
  const [loading, setLoading] = useState(true);


  return (
    <Router>
      <div>
        <Header />
        <Routes>
          <Route path="/" element={<Mainhome />} />
          <Route path="/select-page" element={<Selectpage />} />
          <Route path="/Page1" element={<Page1 />} />
          <Route path="/Page2" element={<Page2 />} />
          <Route path="/Page3" element={<Page3 />} />
          <Route path="/Page4" element={<Page4 />} />
          <Route path="/Slot" element={<Slot />} />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/Refund" element={<Refund />} />
          <Route path="/TermAndCondition" element={<TermAndCondition />} />
          <Route path="/Success_Story" element={<SuccessStoryList />} />
          <Route path="/Success_Story/:id/:visitorcnt" element={<SuccessStory />} />
          <Route path="/Success" element={<SuccessPage />} />
          <Route path="/Fail" element={<FailPage />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;